import React from "react";
import '../../components/Layout/layout.css';

const BulletPointsNum = ({ children }) => {
  return (
    <div className="bullet-points-num">
      <ol>
        <li>
          {children}
        </li>
    
      </ol>
    </div>
        
  );
};

export default BulletPointsNum;
